.VideoInfoHomeCard {
  padding: 18px;
}

.VideoInfoHomeCard h6 {
  font-weight: bold;
  font-size: 1.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.VideoInfoHomeCard p {
  margin: 0px;
  font-weight: 300;
}
