.formContainer a,
.signupForm a {
  color: var(--pink-focused);
  color: black;
  font-weight: 700;
}

.SignupForm button,
.LoginForm button {
  margin-bottom: 4rem;
}

.formContainer h2,
.signupForm h2 {
  font-weight: 700;
  font-size: 1.8rem;
}

.loginSignupToaster {
  position: relative;
  background-color: rgb(235, 235, 235);
  padding: 6px 10px 6px 40px;
  border-radius: 6px;
  border-left: 3px solid rgb(255, 126, 126);
  display: flex;
  align-items: center;
}

.loginSignupToaster .Eye-EyeLash {
  position: absolute;
  left: 14px;
}

.SignupForm,
.LoginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.SignupForm,
.LoginForm {
  min-height: calc(100vh - 48px);
  /* min-height: stretch;
  min-height: -webkit-fill-available; */
}
