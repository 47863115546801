video::-webkit-media-controls {
  display: none !important;
}

.video-home-grid {
  display: flex;
  justify-content: center;
}

.videocard-container {
  position: relative;
  background-color: white;
  margin: 0 0 20px;
  flex-shrink: 0;
  border-radius: 10px;
}

/* Video */
.locationmap-container .VideoPlayer,
.locationmap-container .VideoPlayer video {
  height: 234px;
  transition: height 0.15s ease-out;
}

.locationmap-container .VideoPlayer:hover,
.locationmap-container .VideoPlayer:hover video {
  height: 280px;
  transition: height 0.25s ease-in;
}

.videocard-container video {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 10px 10px 0 0;
}

/* Aside */
.videocard-container aside {
  display: flex;
  align-items: center;
  padding: 15px;
  height: 95px;
  transition: height 0.15s ease-out;
  position: relative;
}

.videocard-container aside:hover {
  cursor: pointer;
}

.videocard-container aside a::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.videocard-container .VideoPlayer:hover + aside {
  height: 50px;
  transition: height 0.25s ease-in;
}

/* Avatar */
.videocard-avatar-container {
  height: 30px;
  width: 30px;
  border: 2px solid rgba(64, 64, 64, 0.282);
  background-color: rgba(0, 0, 0, 0.067);
  box-sizing: content-box;
  border-radius: 50%;
  padding: 2px;
  transition: height, width, 0.15s ease-out;
  flex-shrink: 0;
  margin-right: 10px;
}

.videocard-container .VideoPlayer:hover + aside .videocard-avatar-container {
  height: 1.7rem;
  width: 1.7rem;
  transition: height, width, 0.25s ease-in;
}

.videocard-avatar-container img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

/* Description */
.video-description {
  flex: 1;
  width: 0;
}

.video-description h6 {
  font-weight: bold;
  width: 100%;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: margin 0.15s ease-out;
}

.videocard-container .VideoPlayer:hover ~ aside .video-description h6 {
  margin: 24px 0 0;
  transition: margin 0.25s ease-in;
}

.video-description p {
  color: rgb(107, 107, 107);
  font-weight: 400;
  font-size: 0.9rem;
  margin-bottom: 0px;
  transition: color 0.15s ease-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.videocard-container .VideoPlayer:hover ~ aside .video-description p {
  color: transparent;
  transition: color 0.25s ease-in;
}

/* Tablet */
@media (min-width: 768px) {
  .video-home-grid {
    width: 25%;
  }

  .videocard-container {
    width: 270px;
    height: 344px;
    border-radius: 10px;
    margin: 0 0 30px;
  }

  /* Video */
  .videocard-container video {
    height: 254px;
  }

  .videocard-container video:hover {
    height: 280px;
  }
}
