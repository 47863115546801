.Profile {
  position: relative;
  box-sizing: border-box;
  width: 100vw;
  max-width: 1000px;
  margin: 0 auto;
  height: 100%;
}

.Profile header {
  width: 100%;
  padding-top: 40px;
}

.Profile .profile-info {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  width: 100%;
}

.Profile .profile-info button {
  border-color: rgb(229, 229, 229);
  font-size: 14px;
  margin: 0;
}

.Profile .profile-info button:first-of-type {
  margin-bottom: 10px;
}

.Profile .profile-info h4 {
  font-weight: bold;
}

.Profile .profile-info p {
  margin-bottom: 0;
}

.Profile .profile-info div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Profile .profile-logout {
  position: relative;
  text-align: right;
  margin-top: 20px;
}

.Profile .profile-logout button {
  position: relative;
  border: none;
  padding: 0;
  height: fit-content;
  width: fit-content;
  font-weight: bold;
  font-size: 14px;
  padding-right: 20px;
  margin-right: 30px;
}

.Profile .profile-logout button svg {
  position: absolute;
  right: 0;
  top: 4px;
}

.Profile header img {
  height: 70px;
  width: 70px;
}

.Profile .profile-videos-container .videos-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  column-gap: 2px;
  row-gap: 2px;
}

.Profile .profile-videos-container .no-content {
  padding: 30px;
  text-align: center;
  color: rgb(79, 79, 79);
}

.Profile .profile-videos-container video {
  aspect-ratio: 1 / 1;
  margin-bottom: -6px;
  background-color: black;
  width: 100%;
  cursor: pointer;
}

/* @media screen and (min-width: 1200px) {
} */
