@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&family=Yanone+Kaffeesatz:wght@700&display=swap");

/* Mobile 375px */
/* Navbar/NewPostNavbar */
.Navbar,
.NewPostNavbar {
  max-width: 1000px;
  min-width: 375px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 0px 30px;
}

/* Navbar components */
/* Platy */
.platy-nav-container {
  width: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.platy-nav-container a svg {
  height: 48px;
  width: 80px;
}

/* Searchbar */
.searchbar-nav-container {
  display: none;
}

/* Profile */
.profile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-ind-container {
  width: 25px;
  margin: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-ind-container:first-of-type {
  margin-left: 0px;
}
.icon-ind-container:last-of-type {
  margin-right: 0px;
}

.icon-ind-container svg {
  color: var(--dark-900);
  font-size: 22px;
  height: 24px;
  margin: 0px;
}

.nav-avatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 0.1rem solid var(--dark-900);
}

.nav-avatar img {
  object-fit: cover;
}

/* New post dropdown */
.Navbar .dropdown a {
  color: var(--dark-900);
  text-decoration: none;
  background-color: transparent;
}

.Navbar .dropdown .dropdown-toggle {
  background-color: transparent;
  width: 25px;
  height: 25px;
  border: none;
  padding: 0px;
  margin: 12px;
}

.Navbar .dropdown .icon-ind-container {
  margin: 0px;
}

.Navbar .dropdown .btn-primary {
  outline: none;
  box-shadow: none;
}

.Navbar .dropdown-menu {
  border: none;
  border: 0.064rem solid rgb(198, 198, 198);
  background-color: rgb(255, 255, 255);
  outline: none;
  padding: 0px;
  transform: translate3d(33px, 42px, 0px) !important;
  min-width: 100px;
  font-size: 0.94rem;
  border-radius: 16px;
}

.Navbar .dropdown-menu svg {
  width: 20px;
  height: 20px;
  margin: 0px 0px 4px 30px;
  color: var(--dark-900);
}

.Navbar .dropdown-menu > a {
  border-bottom: 0.07em solid rgb(152, 152, 152);
}
.Navbar .dropdown-menu > a:last-of-type {
  border-bottom: none;
}

/* Modal - New post log in */
.modal-dialog-centered {
  margin: auto;
}

.singuplogin-modal .goback {
  position: absolute;
  font-size: 1.3rem;
  font-weight: 600;
  right: 32px;
  top: 20px;
}

.singuplogin-modal .goback:hover {
  cursor: pointer;
}

.singuplogin-modal .modal-content {
  min-width: 300px;
  max-width: 360px;
  border: none;
  border-radius: 10px;
  height: 380px;
  justify-content: center;
}

/* Tablet */
@media (min-width: 768px) {
  .searchbar-nav-container {
    width: 56vw;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 30px;
  }
}

@media (min-width: 1000px) {
  .Navbar {
    padding: 0px;
  }
}

@media (min-width: 1084px) {
  .Navbar .dropdown-menu {
    transform: translate3d(-33px, 42px, 0px) !important;
  }
}
