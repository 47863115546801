.AddLocationForm .formContainer {
  padding-top: 20px;
  margin: 0 auto;
}

input[name="videoFile"] {
  display: none;
}

/* File upload button */
label[for="uploadFile"],
.loading {
  background-color: var(--pink-focused);
  background-color: var(--dark-900);
  color: rgb(240, 240, 240);
  border-radius: 10px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

label[for="uploadFile"]:hover {
  cursor: pointer;
}

label[for="uploadFile"] svg,
.loading svg {
  font-size: 1.6rem;
  margin-left: 30px;
}

.loading {
  background-color: transparent;
  color: black;
  border: none;
  margin-top: 16px;
}

/* Error message */
.errorMessage {
  display: flex;
  align-items: center;
  padding: 0px 4px;
  background-color: rgb(225, 225, 225);
  background-color: white;
  border-radius: 10px;
  padding: 10px 20px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 20px;
}

.errorMessage div:first-of-type {
  height: 20px;
  line-height: 16px;
  margin-right: 16px;
}

.errorMessage div:last-of-type {
  position: absolute;
  top: 4px;
  right: 6px;
  padding: 0px 4px 3px;
}

.errorMessage div:last-of-type svg {
  font-size: 0.9rem;
}

/* Error title */
.errorMessage p:first-of-type {
  font-weight: 700;
  margin: 0px;
}

/* Error message content */
.errorMessage p:last-of-type {
  font-size: 0.88rem;
  color: rgb(146, 146, 146);
  align-self: flex-start;
  margin: 0px;
  margin-right: 10px;
}

/* Exclamation circle */
.errorMessage div:first-of-type svg {
  font-size: 1.2rem;
  margin: 0px;
  color: rgb(255, 0, 38);
}

/* Edit */
.AddLocationForm .editLocation {
  margin-top: 20px;
  position: relative;
}

.AddLocationForm .editLocation h6 {
  font-weight: bold;
}

.AddLocationForm .editLocation p:first-of-type {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--dark-800);
  width: calc(100% - 50px);
}

.AddLocationForm .editLocation p:last-of-type {
  font-size: 0.8rem;
  font-weight: bold;
  position: absolute;
  top: 29px;
  right: 0px;
}

.AddLocationForm .editLocation p:last-of-type svg {
  margin-bottom: 3px;
}

.AddLocationForm .VideoPlayer,
.AddLocationForm .VideoPlayer video {
  height: 320px;
  width: 100%;
  margin: 10px 0px 20px;
}
