.EditVideolocation {
  padding: 20px 30px;
  width: 100vw;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

.EditVideolocation .editMainContainer {
  width: 100%;
}

.form-control:focus {
  box-shadow: none;
}

.EditVideolocation .edit-formcontainer {
  padding: 0;
  width: 100%;
}

.EditVideolocation .edit-formcontainer textarea {
  width: 100%;
  max-width: 100%;
}

.TrashFill {
  color: white;
  font-size: 1.1rem;
  position: absolute;
  right: 8px;
  top: 10px;
}

.TrashFill:hover {
  cursor: pointer;
}

.EditVideolocation .VideoPlayer {
  width: 100%;
  height: 600px;
}

.successMessage {
  margin-top: 14px;
}

.successMessage strong {
  text-decoration: underline;
  margin-left: 5px;
}

.successMessage strong:hover {
  cursor: pointer;
}

@media screen and (min-width: 700px) {
  .EditVideolocation .editMainContainer {
    display: flex;
    justify-content: space-between;
  }

  .EditVideolocation .VideoPlayer {
    width: 40vw;
    max-width: 500px;
    height: 40vw;
    max-height: 500px;
    margin-top: 50px;
    margin-left: 20px;
  }

  .EditVideolocation .edit-formcontainer {
    padding: 0;
    width: 100vw;
  }
}

@media screen and (min-width: 1000px) {
  .EditVideolocation {
    padding: 20px 0;
  }
}
