video::-webkit-media-controls {
  display: none !important;
}

.VideoPlayer {
  position: relative;
  margin: 0;
  display: block;
}

.VideoPlayer video {
  cursor: pointer;
  background-color: rgb(26, 26, 26);
  background-color: black;
  height: 100%;
  width: 100%;
}

.play-toggle,
.volume-toggle {
  color: rgb(231, 231, 231);
  color: white;
  height: 28px;
  width: 28px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.385);
}

.play-toggle {
  left: 10px;
}

.play-toggle svg,
.volume-toggle svg  {
  font-size: 1.1rem;
  cursor: pointer;
}

.play {
  padding-left: 0.14em;
  font-size: 1.3rem !important;
}

.volume-toggle {
  right: 10px;
  z-index: 2;
}

.volume-toggle:hover {
  background-color: rgba(60, 60, 60, 0.436);
}

video:hover ~ .play-toggle,
.play-toggle:hover {
  background-color: rgba(60, 60, 60, 0.436);
}