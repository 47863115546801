.VideolocationDetails {
  max-width: 1000px;
  margin: 0 auto;
}

.VideolocationDetails .VideoPlayer {
  height: 64vh;
  width: 100%;
}

.VideolocationDetails .info-primary {
  padding: 10px 30px;
  display: flex;
  background: black;
}

.VideolocationDetails .info-secondary h6,
.VideolocationDetails .info-primary h6 {
  font-weight: bold;
  color: rgb(205, 205, 205);
}

.VideolocationDetails .info-primary > div:first-of-type {
  position: relative;
  height: 36px;
  width: 36px;
  border: 1px solid rgb(65, 65, 65);
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.VideolocationDetails .info-primary > div:first-of-type img {
  width: 100%;
  height: 100%;
}

.VideolocationDetails .info-secondary {
  padding: 12px 10px;
  background: black;
}

.VideolocationDetails .info-secondary h6 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.VideolocationDetails .info-secondary p {
  font-size: 14px;
  color: var(--gray-500);
  margin-bottom: 0;
}
