.app-container {
  margin: 48px auto 0;
}

.mainLoading {
  padding: 20px;
  width: 100vw;
}

.navbar-container {
  width: 100vw;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(223, 223, 223, 0.575);
  position: fixed;
  top: 0;
  height: 48px;
  z-index: 1000;
}
