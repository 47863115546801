/* General map style */
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: black;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: black;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: black;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: black;
}

.upload-file-disabled {
  pointer-events: none;
}

/* Map container */
.locationmap-container {
  position: relative;
  width: 100vw;
  min-height: calc(100vh - 48px);
  /* min-height: stretch;
  min-height: -webkit-fill-available; */
}

/* Map popup video player container */
.locationmap-container .mapboxgl-popup-content {
  padding: 0;
  border-radius: 10px;
  height: 100%;
}

.locationmap-container .video-home-grid,
.locationmap-container .videocard-container {
  width: 240px !important;
  height: 330px !important;
  margin: 0 !important;
}

.locationmap-container .video-home-grid h6 {
  color: var(--text-color);
  font-family: "Lato", sans-serif;
}

@media (min-width: 768px) {
  /* Enter map full screen */
  .locationmap-container .mapboxgl-ctrl-top-right {
    display: block;
  }

  /* Map View div */
  .MapLocations {
    max-width: 1000px;
    margin: 0px auto;
  }

  /* Map container */
  .locationmap-container {
    width: 100%;
    height: 47.6vw;
    height: calc(100vh - 48px);
  }
}
