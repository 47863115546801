.Home {
  position: relative;
}

/* Videos */
.Home .playerContainer {
  overflow: scroll;
  padding: 0px 10px;
  max-width: 100%;
  margin: 0px auto;
}

.Home .playerContainer::-webkit-scrollbar {
  display: none;
}

.Home .videoCard {
  scroll-snap-align: start;
  max-width: 460px;
  min-width: 310px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 20px auto;
  background-color: white;
}

.Home .videoCard .topInfo {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0px 10px;
}

.Home .videoCard .topInfo h6 {
  font-weight: bold;
  margin: 0px;
  margin-left: 10px;
}

.Home .videoCard .bottomInfo p {
  margin: 0px;
}

.Home .VideoPlayer {
  height: 360px;
  width: 100%;
}

.Home .videocard-avatar-container {
  border: 2px solid rgba(0, 0, 0, 0.08);
  background-color: rgba(0, 0, 0, 0.052);
}

.Home .VideoInfoHomeCard {
  cursor: pointer;
}

/* Tablet */
@media (min-width: 768px) {
  /* Home */
  .Home .VideoPlayer {
    height: 500px;
  }
}
