.MapAddLocation > div {
  width: 100vw;
  min-height: calc(100vh - 48px);
  max-width: 1000px;
  margin: 0 auto;
  /* min-height: fill-available;
  min-height: -webkit-fill-available; */
}


/* Tablet */
@media (min-width: 768px) {
  /* Map 
  _____________ */
  .MapAddLocation > div {
    width: 100vw;
    min-height: calc(100vh - 50px);
  }
}