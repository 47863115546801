.MyLocations {
  position: relative;
  max-width: 1000px;
  margin: 0 auto 20px;
  width: 100%;
}

.MyLocations h4 {
  top: 48px;
  padding: 10px 30px;
  left: 0;
  width: 100%;
  background: var(--background-color);
  height: 50px;
  z-index: 2;
  margin-bottom: 0px;
  max-width: 1000px;
  margin: 0 auto;
}

.MyLocations .container-div {
  padding: 2px 30px;
  margin-top: 10px;
}

.MyLocations .mylocations-card {
  background: rgb(236, 236, 236);
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  padding: 20px 14px 14px;
  width: 100%;
}

.MyLocations .mylocations-card .video-container {
  height: 120px;
  width: 120px;
  margin-right: 16px;
  width: 120px;
  flex-shrink: 0;
}

.MyLocations .mylocations-card video {
  height: 100%;
  width: 100%;
}

.MyLocations .mylocations-card .info-container {
  width: calc(100% - 134px);
}

.MyLocations .mylocations-card .info-container h6 {
  font-weight: bold;
  margin-bottom: 0;
}

.MyLocations .mylocations-card .info-container p {
  font-size: 14px;
  color: rgb(102, 102, 102);
  font-weight: 400;
  margin-bottom: 6px;
}

.MyLocations .mylocations-card .info-container h6,
.MyLocations .mylocations-card .info-container p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.MyLocations .MyLocations-edit-button {
  margin-left: 0;
  position: absolute;
  bottom: 2px;
  left: 14px;
  display: flex;
  font-size: 0.9rem;
  width: 35px;
  height: 34px;
  border: none;
  background-color: transparent;
  transition: width 0.15s ease-out;
}

.MyLocations .MyLocations-edit-button:hover {
  background-color: rgba(0, 0, 0, 0.26);
  width: 74px;
  transition: width 0.25s ease-in;
}

.MyLocations .MyLocations-edit-button span {
  position: absolute;
  top: 6px;
  left: 10px;
  color: transparent;
  transition: color 0.15s ease-out;
}

.MyLocations .MyLocations-edit-button svg {
  position: absolute;
  top: 9px;
  left: 6px;
  font-size: 0.9rem;
  transition: left 0.15s ease-out;
}

.MyLocations .MyLocations-edit-button:hover svg {
  left: 50px;
  transition: left 0.25s ease-in;
}

.MyLocations .MyLocations-edit-button:hover span {
  color: black;
  transition: color 0.25s ease-in;
}

@media screen and (min-width: 700px) {
  .MyLocations .mylocations-card .info-container {
    width: calc(86% - 134px);
    display: flex;
    position: relative;
    justify-content: space-between;
  }

  .MyLocations .mylocations-card .info-container > div {
    width: calc(90% / 3);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .MyLocations .mylocations-card .info-container > div:nth-of-type(2) {
    padding: 0 10px;
  }

  .MyLocations .MyLocations-edit-button {
    top: auto;
    left: auto;
    right: 10px;
    bottom: 10px;
  }
}

@media screen and (min-width: 1000px) {
  .MyLocations .container-div {
    padding: 0;
  }

  .MyLocations h4 {
    padding: 10px 0;
  }
}