@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

:root {
  --dark-900: #1f1f1f;
  --dark-800: #303030;
  --gray-700: #5a5a5a;
  --gray-500: #b9b9b9;
  --background-color: rgb(252, 252, 252);
  --pink-focused: #14c8d4;
  --pink-focused: #ec127f;
  --text-color: var(--dark-800);
}

html {
  background-color: var(--background-color);
  font-size: 16px;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior: contain;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-weight: 400;
  color: var(--text-color);
  caret-color: var(--text-color);
  line-height: 1.563;
  font-family: "Lato", sans-serif;
  width: 100vw;
}

h1 {
  font-size: 2.8rem;
  letter-spacing: -0.15rem;
  font-weight: 700;
  line-height: 1;
}

/* Form
____________________________ */

.formContainer {
  max-width: 400px;
  width: 100vw;
  padding: 2.6rem 30px;
}

label {
  /* font-size: 0.9rem; */
  font-weight: bold;
  display: flex;
  margin-top: 1rem;
}

Form > input[type="email"],
input[type="password"],
Form input[type="text"],
Form > input[type="number"],
Form > input[type="file"],
.form-control,
.MainSearchBar {
  width: 380px;
  background-color: white;
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 1px solid var(--gray-500);
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

Form > input[type="email"]::selection,
input[type="password"]::selection,
Form input[type="text"]::selection,
Form > input[type="number"]::selection,
Form > input[type="file"]::selection,
.form-control::selection,
.MainSearchBar::selection {
  background: #e3adf16b; /* WebKit/Blink Browsers */
}

Form > input[type="email"]::placeholder,
Form input[type="text"]::placeholder,
Form > input[type="number"]::placeholder,
input[type="password"]::placeholder,
select,
textarea {
  color: var(--gray-700);
}

Form > input[type="email"],
Form input[type="text"],
Form > input[type="number"],
input[type="password"],
Form > input[type="file"],
select,
textarea {
  width: 100%;
  color: var(--gray-800);
}

select {
  margin: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 1px solid var(--gray-500);
  border-radius: 10px;
  color: var(--text-color);
  height: auto;
  background-color: white;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.304 125.304"><path d="M62.652 103.895L0 21.41h125.304" fill="%23303030"/></svg>');
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: center right 0.5rem;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1rem;
  border: 1px solid var(--gray-500);
  border-radius: 10px;
  resize: vertical;
  box-sizing: border-box;
  padding: 0.65rem 0.5rem;
}

Form > input[type="email"]:focus,
Form input[type="text"]:focus,
Form > input[type="number"]:focus,
input[type="password"]:focus,
Form > input[type="file"]:focus,
select:focus,
textarea:focus,
.form-control:focus {
  outline: none;
  border: 2px solid black;
  border: 2px solid var(--pink-focused);
  padding: 0.5875rem 0.4375rem;
}

.MyCheckbox {
  margin-top: 1rem;
  position: relative;
  height: 35px;
  padding-top: 15px;
}

input[type="checkbox"] {
  position: absolute;
  top: 0px;
}

.checkboxLabel {
  position: absolute;
  bottom: 15px;
  left: 30px;
}

input[type="checkbox"] {
  display: inline-block;
  height: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid var(--gray-500);
  width: 1rem;
  align-self: center;
  margin-right: 0.5rem;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="78.369" height="78.369" viewBox="0 0 78.369 78.369"><path fill="black" d="M78.05 19.015l-48.592 48.59c-.428.43-1.12.43-1.548 0L.32 40.016c-.427-.426-.427-1.12 0-1.547l6.704-6.704c.428-.427 1.12-.427 1.548 0l20.113 20.112 41.113-41.113c.43-.427 1.12-.427 1.548 0l6.703 6.704c.427.427.427 1.12 0 1.548z"/></svg>');
  background-size: contain;
}

.error {
  font-size: 12px;
  font-weight: 700;
  margin-top: 0.25rem;
  color: var(--error-state);
  color: black;
}

Form > button {
  padding: 0.5rem 1.25rem;
  font-size: 0.9rem;
  border-radius: 10px;
  background-color: var(--pink-focused);
  border: none;
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  line-height: initial;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

Form > button[type="submit"] {
  margin-top: 1rem;
}

Form > button:hover {
  cursor: pointer;
}

label.checkbox {
  font-weight: normal;
}

.PasswordInput {
  position: relative;
}

.showPassword {
  margin-top: 0px;
  border-radius: 5px;
  position: absolute;
  top: 40px;
  right: 9px;
}

.Eye-EyeLash {
  font-size: 1.2rem;
  color: #727272;
}

.showPassword:hover {
  cursor: pointer;
}

/* Buttons
____________________ */

button {
  text-align: center;
  border: none;
  border-radius: 12px;
  background-color: white;
  border: 1px solid black;
  color: black;
  width: 120px;
  height: 40px;
  margin: 5px 6px;
  box-sizing: border-box;
}

/* General map style */
/* Close X - Map search geocoder */
.mapboxgl-ctrl-geocoder--button {
  height: fit-content;
  width: fit-content;
}

/* Close x svg */
.mapboxgl-ctrl-geocoder--button svg {
  height: 36px;
  margin-top: 0px;
  padding: 2.2px;
}

/* Enter map full screen */
.mapboxgl-ctrl-top-right {
  display: none;
}

/* Search bar container */
.mapboxgl-ctrl-top-left {
  width: calc(100vw - 20px);
  z-index: 0;
}

/* Geolocation, nav controls */
.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-top-right {
  z-index: 0;
}

/* Search and x-close icon */
.mapboxgl-ctrl-geocoder--button svg,
.mapboxgl-ctrl-geocoder--icon-search {
  fill: var(--dark-800);
}

/* Loading icon */
.mapboxgl-ctrl-geocoder--icon-loading {
  display: none !important;
}

/* Search input */
.mapboxgl-ctrl-geocoder--input,
.suggestions-wrapper {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  color: var(--text-color);
}