.NewPostNavbar > div {
  width: calc(100vw / 3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.NewPostNavbar > div:first-of-type {
  justify-content: start;
}

.NewPostNavbar > div:last-of-type {
  justify-content: end;
}

/* Text */
.NewPostNavbar p {
  height: 16px;
  line-height: 12px;
  margin: 0px;
  font-size: 1.1rem;
  font-weight: bold;
}

/* Close/back left icon */
.NewPostNavbar > div:first-of-type svg {
  font-size: 24px;
}

.NewPostNavbar > div:first-of-type svg:hover,
.NewPostNavbar > div:last-of-type p:hover {
  cursor: pointer;
}

/* Next */
.NewPostNavbar > div:last-of-type p {
  color: var(--pink-focused);
}

/* Share */
button[form="new-post-form"] {
  padding: 0px;
  margin-bottom: 9px;
  width: fit-content;
  height: fit-content;
  border-radius: 0px;
  border: none;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--pink-focused);
}