.small-modal .modal-dialog {
  max-width: 350px;
  text-align: center;
}

.small-modal .modal-delete {
  color: rgb(255, 0, 64);
}

.small-modal .modal-content {
  border: none;
  border-radius: 12px;
  padding: 10px 0px;
}

.small-modal .modal-content h6:hover {
  cursor: pointer;
}

.small-modal .modal-content h6 {
  margin: 0;
}

.small-modal .modal-container {
  height: 80vh;
}

.small-modal .divided-container {
  display: flex;
}

.small-modal .divided-container > div {
  width: 50%;
}

.removing-h4 {
  margin: 0;
  margin-top: 20px;
}

.trash-container {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 20px;
  right: 40px;
  color: rgb(74, 74, 74);
  background-color: transparent;
  border-radius: 50%;
  padding: 5px 10px;
  font-weight: 700;
  font-size: 1.06rem;
  z-index: 5;
  text-align: center;
  transition: color, background-color, 0.20s ease-out;
}

.trash-container:hover {
  cursor: pointer;
  background-color: rgb(234, 233, 233);
  color: rgba(255, 0, 60, 0.883);
  transition: color, background-color, 0.20s ease-in;
}
